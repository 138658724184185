import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'
import { getConfig } from '../configs/config'

const browserHistory = createBrowserHistory({ basename: '' })
const userInfoData = localStorage.getItem('userInfoData')
  ? JSON.parse(localStorage.getItem('userInfoData'))
  : null
const reactPlugin = new ReactPlugin()
const config = getConfig()
const ai = new ApplicationInsights({
  config: {
    instrumentationKey:
      config.APP_INSIGHTS_INSTRUMENTATIONKEY || 'f5de0f0c-0845-4f13-8bfe-4f0b61429f5e',
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    }
  }
})

const customProps = {
  UserEmail: userInfoData?.mail
}
ai.loadAppInsights()
ai.trackPageView({
  properties: customProps
})
// Log user email in every log
ai.addTelemetryInitializer((envelope) => {
  if (envelope.data) {
    envelope.data.user_email = userInfoData?.mail
  }
})

export default (Component) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights
export { ai, reactPlugin }
export { customProps }
