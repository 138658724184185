export const advanceSearchFields = [
  {
    label: 'Max response',
    id: 'maxTokens',
    min: 500,
    max: 5000,
    roundOff: true,
    infoMsg:
      "Set a limit on the number of tokens per model response. The API supports a maximum of 4000 tokens shared between the prompt (including system message, examples, message history, and user query) and the model's response. One token is roughly 4 characters for typical English text."
  }
]

//export const chatSettingConfig = [...advanceSearchFields]

export const settingDefaultValue = {
  maxTokens: 4096,
  temperature: 0,
  topP: 1.0,
  frequencyPenalty: 0,
  presencePenalty: 0,
  seed: 42,
  answerGenerationModel: 'gpt-4o',
  chatStyle: 'Precise'
}

export const dataBoxDefaultValues = {
  name: '',
  description: '',
  sampleQuestion1: '',
  sampleQuestion2: '',
  sampleQuestion3: '',
  visibility: 1,
  embeddingModel: 'OpenAI',
  dataChunkingLogic: 'Sliding Window',
  sampleQuestions: [
    {
      question: ''
    }
  ],
  teamId: null,
  ...settingDefaultValue
}

export const dropDownConfig = [
  {
    name: 'embeddingModel',
    label: 'Embedding model',
    optionKey: 'embeddingModels',
    isDisableInEditMode: true
  },
  {
    name: 'answerGenerationModel',
    label: 'Answer generation model',
    optionKey: 'answerGenerationModels'
  },
  {
    name: 'dataChunkingLogic',
    label: 'Data chunking logic',
    optionKey: 'dataChunkingLogic',
    isDisableInEditMode: true
  }
]

export const searchType = [
  {
    name: 'answerGenerationModel',
    label: 'Answer generation model',
    optionKey: 'answerGenerationModels'
  },
  {
    name: 'retrievalMode',
    label: 'Retrieval mode',
    optionKey: 'searchTypes'
  }
]

export const settingValues = {
  Creative: { temperature: 0.7, topP: 0.95 },
  Balanced: { temperature: 0.3, topP: 0.5 },
  Precise: { temperature: 0, topP: 1.0 }
}
