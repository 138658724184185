export const localConfig = {
  REACT_APP_AZURE_CLIENT_ID: '84316f31-c6e3-4f94-85d7-12b48efee2be',
  REACT_APP_API_URL: 'https://as-mg-dev1-t1-weu-docai-01.azurewebsites.net',
  REACT_APP_AZURE_REDIRECT_URL: 'http://localhost:3000',
  REACT_APP_AZURE_AUTHORITY:
    'https://login.microsoftonline.com/aa42167d-6f8d-45ce-b655-d245ef97da66',
  REACT_APP_AZURE_SCOPE: 'api://ab98ed05-b8ad-4621-9bb3-ad04e9847116/user_impersonation',
  APP_INSIGHTS_INSTRUMENTATIONKEY: 'f5de0f0c-0845-4f13-8bfe-4f0b61429f5e',
  REACT_APP_VERSION: '20240812.1'
}

export const getConfig = () => {
  if (process.env.NODE_ENV === 'development') {
    return localConfig
  } else {
    return window.config
  }
}
