import { combineReducers } from 'redux'
import userContextReducer from './userContextReducer'
import layoutReducer from './layoutReducer'
import navbarReducer from './navbarReducer'
import databoxReducer from './databoxReducer'
import chatReducer from './chatReducer'

const rootReducer = combineReducers({
  layoutReducer,
  navbarReducer,
  userContextReducer,
  databoxReducer,
  chatReducer
})

export default rootReducer
