/* eslint-disable no-duplicate-case */
import { SET_USERCONTEXT, SET_USER_DETAILS } from '../constants/userContextConstants'

const initialState = {
  userContext: {
    name: null,
    accessToken: null,
    id: null,
    roles: []
  },
  userDetails: {
    name: null,
    mail: null,
    objectId: null,
    roles: null,
    isAttested: null
  }
}

export default function userContextReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERCONTEXT:
      return {
        ...state,
        userContext: action.payload
      }
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      }
    default:
      return state
  }
}
